import { Flex, type FlexProps, Text } from '@chakra-ui/react'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'
import { BORDER_RADIUS_PILL } from 'theme/constants'

type StepProps = {
  step?: number
  title: string
  subTitle: string
  isActive?: boolean
}

export const Step = ({ step, isActive, subTitle, title }: StepProps) => {
  const COLORS = useThemeColors()

  return (
    <Flex alignItems="center">
      <Flex
        boxSize={6}
        p={1}
        borderRadius={BORDER_RADIUS_PILL}
        bgColor={isActive ? COLORS.zircuitPrimary : COLORS.grey06}
        justifyContent="center"
        alignItems="center"
      >
        <Text variant="text3medium" color={isActive ? COLORS.white : COLORS.grey02}>
          {step}.
        </Text>
      </Flex>
      <Text ml={2} color={isActive ? COLORS.zircuitPrimary : COLORS.dark01} variant="text2medium">
        {title}
      </Text>
      <Text ml="auto" variant="text2regular" color={COLORS.grey02}>
        {subTitle}
      </Text>
    </Flex>
  )
}

type StepperProps = {
  children: React.ReactNode
  activeStepIndex: number
} & FlexProps

export const Stepper = ({ children, activeStepIndex, ...props }: StepperProps) => {
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement, {
        isActive: index === activeStepIndex,
        step: index + 1,
      })
    }
    return child
  })

  return (
    <Flex gap={3} flexDir="column" {...props}>
      {childrenWithProps}
    </Flex>
  )
}
