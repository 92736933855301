import { Flex, type FlexProps, Text } from '@chakra-ui/react'
import React from 'react'
import { ExternalLink } from 'components/ExternalLink/ExternalLink'
import { useThemeColors } from 'hooks/useThemeColors'

type ExternalLinkProps = React.ComponentProps<typeof ExternalLink>

type ExternalLinkLabelProps = {
  label: string
  linkLabel: ExternalLinkProps['label']
  href?: ExternalLinkProps['href']
} & FlexProps

export const ExternalLinkLabel = ({ label, linkLabel, href, ...props }: ExternalLinkLabelProps) => {
  const COLORS = useThemeColors()

  return (
    <Flex alignItems="center" justifyContent="space-between" gap={4} {...props}>
      <Text variant={{ base: 'text3regular', md: 'text2regular' }} color={COLORS.grey02}>
        {label}
      </Text>
      {href ? (
        <ExternalLink variant="secondary" label={linkLabel} href={href} iconPosition="right" />
      ) : (
        <Text variant={{ base: 'text3regular', md: 'text2regular' }} color={COLORS.grey02}>
          -
        </Text>
      )}
    </Flex>
  )
}
