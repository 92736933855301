export const MainnetL2USDCAdapter = {
  address: '0xa689746623D323dA8FCC41a0b3FBca446236B250',
  abi: [
    {
      type: 'constructor',
      inputs: [
        { name: '_bridge', type: 'address', internalType: 'address' },
        { name: '_remoteToken', type: 'address', internalType: 'address' },
        { name: '_usdc', type: 'address', internalType: 'address' },
        { name: '_owner', type: 'address', internalType: 'address' },
      ],
      stateMutability: 'nonpayable',
    },
    {
      name: 'AddressEmptyCode',
      type: 'error',
      inputs: [{ name: 'target', type: 'address', internalType: 'address' }],
    },
    {
      name: 'AddressInsufficientBalance',
      type: 'error',
      inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
    },
    { name: 'BridgeCannotBeZeroAddress', type: 'error', inputs: [] },
    { name: 'EnforcedPause', type: 'error', inputs: [] },
    { name: 'ExpectedPause', type: 'error', inputs: [] },
    { name: 'FailedInnerCall', type: 'error', inputs: [] },
    { name: 'OnlyBridgeCanMintAndBurn', type: 'error', inputs: [] },
    {
      name: 'OwnableInvalidOwner',
      type: 'error',
      inputs: [{ name: 'owner', type: 'address', internalType: 'address' }],
    },
    {
      name: 'OwnableUnauthorizedAccount',
      type: 'error',
      inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
    },
    { name: 'RemoteTokenCannotBeZeroAddress', type: 'error', inputs: [] },
    {
      name: 'SafeERC20FailedOperation',
      type: 'error',
      inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
    },
    { name: 'USDCCannotBeZeroAddress', type: 'error', inputs: [] },
    { name: 'USDCMintFailed', type: 'error', inputs: [] },
    {
      name: 'OwnershipTransferStarted',
      type: 'event',
      inputs: [
        { name: 'previousOwner', type: 'address', indexed: true, internalType: 'address' },
        { name: 'newOwner', type: 'address', indexed: true, internalType: 'address' },
      ],
      anonymous: false,
    },
    {
      name: 'OwnershipTransferred',
      type: 'event',
      inputs: [
        { name: 'previousOwner', type: 'address', indexed: true, internalType: 'address' },
        { name: 'newOwner', type: 'address', indexed: true, internalType: 'address' },
      ],
      anonymous: false,
    },
    {
      name: 'Paused',
      type: 'event',
      inputs: [{ name: 'account', type: 'address', indexed: false, internalType: 'address' }],
      anonymous: false,
    },
    {
      name: 'Unpaused',
      type: 'event',
      inputs: [{ name: 'account', type: 'address', indexed: false, internalType: 'address' }],
      anonymous: false,
    },
    {
      name: 'BRIDGE',
      type: 'function',
      inputs: [],
      outputs: [{ name: '', type: 'address', internalType: 'address' }],
      stateMutability: 'view',
    },
    {
      name: 'REMOTE_TOKEN',
      type: 'function',
      inputs: [],
      outputs: [{ name: '', type: 'address', internalType: 'address' }],
      stateMutability: 'view',
    },
    {
      name: 'USDC',
      type: 'function',
      inputs: [],
      outputs: [{ name: '', type: 'address', internalType: 'contract IUSDC' }],
      stateMutability: 'view',
    },
    {
      name: 'acceptOwnership',
      type: 'function',
      inputs: [],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      name: 'balanceOf',
      type: 'function',
      inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
      outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
      stateMutability: 'view',
    },
    {
      name: 'bridge',
      type: 'function',
      inputs: [],
      outputs: [{ name: '', type: 'address', internalType: 'address' }],
      stateMutability: 'view',
    },
    {
      name: 'burn',
      type: 'function',
      inputs: [
        { name: '_from', type: 'address', internalType: 'address' },
        { name: '_amount', type: 'uint256', internalType: 'uint256' },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      name: 'mint',
      type: 'function',
      inputs: [
        { name: '_to', type: 'address', internalType: 'address' },
        { name: '_amount', type: 'uint256', internalType: 'uint256' },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      name: 'owner',
      type: 'function',
      inputs: [],
      outputs: [{ name: '', type: 'address', internalType: 'address' }],
      stateMutability: 'view',
    },
    { name: 'pause', type: 'function', inputs: [], outputs: [], stateMutability: 'nonpayable' },
    {
      name: 'paused',
      type: 'function',
      inputs: [],
      outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
      stateMutability: 'view',
    },
    {
      name: 'pendingOwner',
      type: 'function',
      inputs: [],
      outputs: [{ name: '', type: 'address', internalType: 'address' }],
      stateMutability: 'view',
    },
    {
      name: 'remoteToken',
      type: 'function',
      inputs: [],
      outputs: [{ name: '', type: 'address', internalType: 'address' }],
      stateMutability: 'view',
    },
    {
      name: 'renounceOwnership',
      type: 'function',
      inputs: [],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      name: 'supportsInterface',
      type: 'function',
      inputs: [{ name: '_interfaceId', type: 'bytes4', internalType: 'bytes4' }],
      outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
      stateMutability: 'pure',
    },
    {
      name: 'transferOwnership',
      type: 'function',
      inputs: [{ name: 'newOwner', type: 'address', internalType: 'address' }],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    { name: 'unpause', type: 'function', inputs: [], outputs: [], stateMutability: 'nonpayable' },
    {
      name: 'usdc',
      type: 'function',
      inputs: [],
      outputs: [{ name: '', type: 'address', internalType: 'address' }],
      stateMutability: 'view',
    },
  ],
} as const
