import { ethers } from 'ethers'
import type { Address } from 'viem'

const FUNCTION_SIGNATURES = {
  bridgeETH: '0x09fc8843',
  bridgeETHTo: '0xe11013dd',
  bridgeERC20: '0x87087623',
  bridgeERC20To: '0x540abf73',
  depositERC20To: '0x838b2520',
  withdrawTo: '0xa3a79548',
}

/**
 * Extracts the 'to' address from the input data of a transaction.
 *
 * @param {string} inputData - The input data of the transaction.
 * @param {Address} from - The 'from' address of the transaction. Used as the 'to' address for non '...To' methods.
 * @returns {Address | null} The extracted 'to' address, or null if it couldn't be extracted.
 */
export const extractToAddress = (inputData: string, from: Address): Address | null => {
  const functionSignature = inputData.slice(0, 10)
  const abiCoder = new ethers.AbiCoder()

  const data = `0x${inputData.slice(10)}`

  switch (functionSignature) {
    case FUNCTION_SIGNATURES.bridgeETH:
    case FUNCTION_SIGNATURES.bridgeERC20: {
      return from
    }
    case FUNCTION_SIGNATURES.bridgeETHTo: {
      const [to] = abiCoder.decode(['address', 'uint32', 'bytes'], data)
      return to as Address
    }
    case FUNCTION_SIGNATURES.bridgeERC20To:
    case FUNCTION_SIGNATURES.depositERC20To: {
      const [, , to] = abiCoder.decode(
        ['address', 'address', 'address', 'uint32', 'uint32', 'bytes'],
        data,
      )
      return to as Address
    }
    case FUNCTION_SIGNATURES.withdrawTo: {
      const [, to] = abiCoder.decode(['address', 'address', 'uint256', 'uint32', 'bytes'], data)
      return to as Address
    }
    default:
      return null
  }
}
