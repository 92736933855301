export const getWithdrawERC20FunctionName = (
  isWstETH: boolean,
  isSameReceivingAddress: boolean,
): 'withdraw' | 'withdrawTo' | 'bridgeERC20' | 'bridgeERC20To' => {
  if (isWstETH) {
    return isSameReceivingAddress ? 'withdraw' : 'withdrawTo'
  }

  return isSameReceivingAddress ? 'bridgeERC20' : 'bridgeERC20To'
}
