import { Box, Progress, Text } from '@chakra-ui/react'
import React from 'react'
import { EXIT_TXN_STATUS_LABELS } from 'constants/common'
import type {
  RecentTransactionsListItemStatus as RecentTxItemStatus,
  TransactionListItemExitStatus as TxItemExitStatus,
} from 'generated/reactQueryClient'
import { TransactionListItemStatus as TxItemStatus } from 'generated/reactQueryClient'
import { useThemeColors } from 'hooks/useThemeColors'

const STATUS_PROGRESS_VALUES = {
  // L1L2 statuses
  pending: 50,
  failed: 100,
  successful: 100,
  replaced: 100,
  // L2L1 statuses
  waiting: 25,
  ready_to_prove: 50,
  in_challenge_period: 75,
  ready_to_relay: 90,
  relayed: 100,
}

const STATUS_PROGRESS_LABELS = {
  // L1L2 statuses
  failed: TxItemStatus.failed,
  pending: TxItemStatus.pending,
  successful: TxItemStatus.successful,
  replaced: TxItemStatus.replaced,
  // L2L1 statuses
  waiting: EXIT_TXN_STATUS_LABELS.waiting,
  ready_to_prove: EXIT_TXN_STATUS_LABELS.ready_to_prove,
  in_challenge_period: EXIT_TXN_STATUS_LABELS.in_challenge_period,
  ready_to_relay: EXIT_TXN_STATUS_LABELS.ready_to_relay,
  relayed: EXIT_TXN_STATUS_LABELS.relayed,
}

type Statuses = keyof typeof TxItemExitStatus | keyof typeof RecentTxItemStatus

type ProgressBarProps = {
  status: Statuses
}

export const ProgressBar = ({ status }: ProgressBarProps) => {
  const COLORS = useThemeColors()

  return (
    <Box>
      <Progress
        height="2px"
        value={STATUS_PROGRESS_VALUES[status]}
        {...(status === 'failed' && { variant: 'secondary' })}
      />
      <Text
        _firstLetter={{ textTransform: 'uppercase' }}
        mt={{ base: 2, md: 4 }}
        variant={{ base: 'caption2regular', md: 'text2regular' }}
        color={COLORS.grey02}
      >
        {STATUS_PROGRESS_LABELS[status]}
      </Text>
    </Box>
  )
}
