import type { ColorMode } from '@chakra-ui/react'
import { zircuit, l1 } from 'constants/network'

export const getImageNameByChainId = (id: number, theme: ColorMode) => {
  switch (id) {
    case zircuit.id:
      return `zircuit_${theme}`
    case l1.id:
      return 'l1'
    default:
      return null
  }
}
