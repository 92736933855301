import type { Address } from 'viem'

export const getDepositOrWithdrawETHArguments = (
  isSameReceivingAddress: boolean,
  {
    to,
    minGasLimit,
    extraData,
  }: {
    to: Address
    minGasLimit: number
    extraData: Address
  },
) =>
  isSameReceivingAddress
    ? [minGasLimit, extraData] // bridgeETH
    : [to, minGasLimit, extraData] // bridgeETHTo
